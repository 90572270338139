import Box from "@material-ui/core/Box";
import React from "react";
import {useLocation} from "react-router-dom";
import "./main.scss";

function Acknowledge() {

  const { state } = useLocation();
  
  return (
    <Box style={{textAlign: "center"}}>
      <h1>{state}</h1>
    </Box>
  );
}

export default Acknowledge