import {
  Button,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import React,{useEffect,useState} from "react";
import Geocode from "react-geocode";
import toast,{Toaster} from 'react-hot-toast';
import {useIntl} from "react-intl";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import {useNavigate} from "react-router-dom";
import config from "../constant";
import {useGeolocation} from "../contexts/GeolocationContext";
import useMonitor from "../hooks/monitor/useMonitor";
import AutoSuggestPlace from "./AutoSuggestPlace";
import withIntl from "./hoc/withIntl";
import "./main.scss";


const DEFAULT_CONTACT_INFO = {
  name: "",
  phoneNumber: "",
  location: "",
  lat: "",
  long: "",
  landmark: "",
  emergencyType: "",
};

Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAPS_API_KEY);
Geocode.enableDebug();


function LeftSide({ pageSettings, isLoading }) {

  if(isLoading) return
  
  const navigate = useNavigate();
  const intl = useIntl()
  const [contactInfo, setContactInfo] = useState(DEFAULT_CONTACT_INFO);
  const { position, error } = useGeolocation();
  const { sendContactInformation, alertMessageText } = useMonitor()

  useEffect(() => {
    console.log({ position });
    if (position) {
      Geocode.fromLatLng(
        position.coords.latitude,
        position.coords.longitude
      ).then(
        (response) => {
          const addressFromGPS = response.results[0];

          const _contactInfo = { ...contactInfo };
          _contactInfo.location = addressFromGPS.formatted_address;
          _contactInfo.locationCoordinates = {
            type: "Point",
            coordinates: [
              addressFromGPS.geometry?.location?.lat,
              addressFromGPS.geometry?.location?.lng,
            ],
          };
          setContactInfo(_contactInfo);
        },
        (error) => {
          console.error(error);
        }
      );
    }
  }, [position]);

  const handleChangeEmergencyInfo = (event) => {
    const { name, value } = event.target;
    const _contactInfo = { ...contactInfo };
    _contactInfo[name] = value;
    setContactInfo(_contactInfo);
  };

  const handleDestination = (place) => {
    const _contactInfo = { ...contactInfo };
    _contactInfo.location = place.formatted_address;
    _contactInfo.locationCoordinates = {
      type: "Point",
      coordinates: [
        place.geometry?.location?.lat(),
        place.geometry?.location?.lng(),
      ],
    };
    setContactInfo(_contactInfo);
  };


  const handleSendInfo = async (event) => {
    try {
      await sendContactInformation(
        event,
        { ...contactInfo, linkedMonitor: pageSettings?.linked_monitor }, // dynamic linked monitor
        setContactInfo,
        DEFAULT_CONTACT_INFO
      )
      // toast.success(`${intl.formatMessage({ id: 'your_problem_sent' })} ${pageSettings?.organization_name} ${intl.formatMessage({ id: 'they_will_call_back' })}`, { duration: 10000, position: 'bottom-center' })
      navigate('/acknowledge',{state: `${intl.formatMessage({id: 'your_problem_sent'})} ${pageSettings?.organization_name} ${intl.formatMessage({id: 'they_will_call_back'})}`});
    } catch (error) {
      toast.error('There is an error!', { position: 'bottom-center' })
    }
  }

  return (
    <Box dir={config.RTL_LANGUAGES.includes(pageSettings?.language) ? 'rtl' : 'ltr'}>
      <Box style={{ textAlign: "center" }}>
        <img
          src={'public/images/logo.png'}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null; // prevents looping
            currentTarget.src = "../../images/logo.png";
          }}
          className="logoImg"
          alt="emergency-logo"
        />
      </Box>
      <Typography className="paddingTop">
        <Typography>{pageSettings?.page_title ?? intl.formatMessage({ id: 'greeting' })}</Typography>
        <Typography className="paddingBottom">
          <h5>{intl.formatMessage({ id: 'registerText' })}</h5>
          {intl.formatMessage({ id: 'registerDescription' })}
        </Typography>
        <Typography>
          {intl.formatMessage({ id: 'locationMessage' })}
        </Typography>
      </Typography>

      <Box className="paddingBottom">
        <TextField
          variant="outlined"
          type="text"
          placeholder={intl.formatMessage({ id: 'namePlaceholder' })}
          fullWidth={true}
          size="small"
          name="name"
          value={contactInfo.name}
          onChange={handleChangeEmergencyInfo}
        />
      </Box>
      <Box className="paddingBottom">
        <PhoneInput
          country="cl"
          value={contactInfo.phoneNumber}
          onChange={(value, country, e, formattedValue) => {
            setContactInfo({ ...contactInfo, phoneNumber: value });
          }}
          countryCodeEditable={false}
          enableSearch
          inputProps={{
            name: "phone",
            required: true,
          }}
          inputStyle={{
            width: "100%",
            borderColor: "#e4e4e7",
            borderRadius: "6px",
          }}
          buttonStyle={{
            backgroundColor: "white",
            borderColor: "#e4e4e7",
          }}
        />
      </Box>
      <Box className="paddingBottom">
        <AutoSuggestPlace
          addressFromGPS={contactInfo.location}
          handleDestination={handleDestination}
        />
      </Box>

      <Box className="paddingBottom">
        <InputLabel className="marginBottom">
          {intl.formatMessage({ id: 'landmarkLabel' })}
        </InputLabel>
        <TextField
          variant="outlined"
          type="text"
          placeholder={intl.formatMessage({ id: 'landmarkLabel' })}
          fullWidth={true}
          size="small"
          name="landmark"
          value={contactInfo.landmark}
          onChange={handleChangeEmergencyInfo}
        />
      </Box>
      <Box className="paddingBottom">
        <InputLabel className="marginBottom">
          {intl.formatMessage({ id: 'emergencyTypeLabel' })}
        </InputLabel>
        <Select
          name="emergencyType"
          fullWidth={true}
          value={contactInfo.emergencyType}
          onChange={handleChangeEmergencyInfo}
          variant="outlined"
          displayEmpty
        >
          <MenuItem value="">
            <em>{intl.formatMessage({ id: 'emergencyTypeLabel' })}</em>
          </MenuItem>
          <MenuItem value="Accident">{intl.formatMessage({ id: 'accident' })}</MenuItem>
          <MenuItem value="Mechanical">{intl.formatMessage({ id: 'mechanical' })}</MenuItem>
          <MenuItem value="Health">{intl.formatMessage({ id: 'health' })}</MenuItem>
          <MenuItem value="Crimen">{intl.formatMessage({ id: 'crime' })}</MenuItem>
          <MenuItem value="Fire">{intl.formatMessage({ id: 'fire' })}</MenuItem>
          <MenuItem value="Other">{intl.formatMessage({ id: 'other' })}</MenuItem>
        </Select>
      </Box>
      <Box>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSendInfo}
        >
          {intl.formatMessage({ id: 'send' })}
        </Button>
      </Box>
      <Toaster />
    </Box>
  );
}

export default withIntl(LeftSide)