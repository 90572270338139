import React from 'react';
import { IntlProvider } from "react-intl";
import messages_ar from "../../nls/ar.json";
import messages_en from "../../nls/en.json";
import messages_es from "../../nls/es.json";
import messages_fr from "../../nls/fr.json";
import messages_he from "../../nls/he.json";
import messages_hi from "../../nls/hi.json";
import messages_it from "../../nls/it.json";
import messages_ja from "../../nls/ja.json";
import messages_nl from "../../nls/nl.json";
import messages_pt from "../../nls/pt.json";
import messages_ru from "../../nls/ru.json";

// Define the supported locales and messages
const messages = {
    ar: messages_ar,
    en: messages_en,
    es: messages_es,
    fr: messages_fr,
    he: messages_he,
    hi: messages_hi,
    it: messages_it,
    ja: messages_ja,
    nl: messages_nl,
    pt: messages_pt,
    ru: messages_ru,
};

const withIntl = (Component) => (props) => {
    const lang = messages[props.pageSettings?.language] ? props.pageSettings?.language : 'es'
    return (
        <IntlProvider locale={lang} messages={messages[lang]}>
            <Component {...props} pageSettings={props.pageSettings} />
        </IntlProvider>
    )
}

export default withIntl