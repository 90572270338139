import {useJsApiLoader} from "@react-google-maps/api";
import {useEffect,useMemo} from "react";
import {BrowserRouter,Route,Routes} from "react-router-dom";
import "./App.css";
import HomePage from "./component/HomePage";
import Acknowledge from "./component/acknowledge";
import MyMap from "./component/MyMap";

function App() {
  const libraries = useMemo(() => ["places"], []);
  const { isLoaded } = useJsApiLoader({
    // @ts-ignore
    libraries: libraries,
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  });

  // useEffect(() => {
  //   console.log({ isLoaded, googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY });
  // }, [isLoaded]);
  
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<MyMap isLoaded={isLoaded} />} />
        <Route path="/form" element={<HomePage />} />
        <Route path="/emergencias" element={<HomePage />} />
        <Route path="/acknowledge" element={<Acknowledge />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
