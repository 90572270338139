import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import useOnclickOutside from "react-cool-onclickoutside";
import { TextField } from "@material-ui/core";
import { useEffect, useState } from "react";

const AutoSuggestPlace = ({ addressFromGPS, handleDestination }) => {
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      // location: { lat: () => 43.6532, lng: () => -79.3832 },
      // radius: 100 * 1000,
    },
    debounce: 300,
  });

  const [location, setLocation] = useState("");

  useEffect(() => {
    setLocation(addressFromGPS);
  }, [addressFromGPS]);

  const ref = useOnclickOutside(() => {
    // When user clicks outside of the component, we can dismiss
    // the searched suggestions by calling this method
    clearSuggestions();
  });

  const handleInput = (e) => {
    // Update the keyword of the input element
    setValue(e.target.value);
    setLocation(e.target.value);
  };

  const handleSelect =
    ({ description }) =>
    () => {
      // When user selects a place, we can replace the keyword without request data from API
      // by setting the second parameter to "false"
      setValue(description, false);
      setLocation(location);
      clearSuggestions();

      // Get latitude and longitude via utility functions
      getGeocode({ address: description }).then((results) => {
        // const coordinates = getLatLng(results[0]);
        console.log("📍 Location: ", results[0]);
        handleDestination(results[0]);
      });
    };

  const renderSuggestions = () =>
    data.map((suggestion) => {
      const {
        place_id,
        structured_formatting: { main_text, secondary_text },
      } = suggestion;

      return (
        <li
          key={place_id}
          style={{
            display: "block",
            borderBottom: "2px solid #cccccc",
            padding: "8px 0px",
          }}
          onClick={handleSelect(suggestion)}
        >
          <strong>{main_text}</strong> <small>{secondary_text}</small>
        </li>
      );
    });

  return (
    <div ref={ref}>
      {/* <input
        value={value}
        onChange={handleInput}
        disabled={!ready}
        placeholder="Location"
        style={{
          width: "94%",
          padding: "10px 12px",
          borderColor: "#eeeeee",
          boxShadow: "none",
          borderStyle: "solid",
          borderRadius: "4px",
        }}
      /> */}

      <TextField
        variant="outlined"
        type="text"
        placeholder="LOCATION"
        fullWidth={true}
        size="small"
        name="name"
        value={location}
        onChange={handleInput}
        disabled={!ready}
      />
      {/* We can use the "status" to decide whether we should display the dropdown or not */}
      {status === "OK" && (
        <ul
          style={{
            borderRadius: "4px",
            border: "2px solid #bebebe",
            paddingRight: "32px",
          }}
        >
          {renderSuggestions()}
        </ul>
      )}
    </div>
  );
};

export default AutoSuggestPlace;
