import { Box } from '@material-ui/core'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import config from '../constant'
import LeftSide from './LeftSide'

function HomePage() {
  const [isLoading, setIsLoading] = useState(false)
  const [pageSettings, setPageSettings] = useState({ page_title: '' })
  
  useEffect(() => {
    getOrganization()
  }, [])

  const getOrganization = async () => {
    setIsLoading(true)
    try {
      const response = await axios.get(`${config.BASE_URL}/v1/admin/organization/${config.SUB_DOMAIN_PREFIX}`);
      console.log('response.data', response.data)
        setPageSettings(response.data)
        setIsLoading(false)

    } catch (e) {
      console.log("Error in submitting form", e);
      setIsLoading(false)
    }
  }
  return (
    // <Box className='pageBorder'>
    //   <Grid container spacing={2}>
    //     <Grid item xs={6}>
    //       <LeftSide />
    //     </Grid>
    //   </Grid>
    // </Box>
    <Box className='pageBorder'>
      <Box className='homePageContainer'>
        <LeftSide pageSettings={pageSettings} isLoading={isLoading}/>
      </Box>
    </Box>
  )
}

export default HomePage
