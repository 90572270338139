import axios from "axios";
import { useEffect, useRef } from 'react';
import constant from "../../constant";

const useMonitor = () => {
    const monitors = useRef([])
    const alertMessageText = useRef('')

    useEffect(() => { getMonitors() }, [])

    const getMonitors = async () => {
        try {
            const response = await axios.get(`${constant.BASE_URL}/v1/admin/monitor/all`);
            monitors.current = response.data
            findMonitor()
        } catch (e) {
            console.log("Error in submitting form", e);
        }
    };

    const findMonitor = () => {
        monitors.current?.forEach(monitor => {
            // console.log('monitor.latitude', monitor.latitude, monitor.longitude)
            // let isWithinRadius = areCoordinatesWithinRadius(monitor.latitude, monitor.longitude, userLat, userLong)
            const isMonitorMatched = monitor.subdomain_prefix === constant.LINKED_MONITOR
            if (isMonitorMatched) {
                alertMessageText.current = `Your problem was sent to ${monitor.organization_name}. They will call you back.`
            }
        })
    }


    const sendContactInformation = async (
        event,
        payload,
        setContactInfo,
        defaultContactInfo,
    ) => {
        // event.preventDefault()
        try {
            await axios.post(`${constant.BASE_URL}/v1/admin/contact`, payload);
            // alert(alertMessageText.current);
            setContactInfo(defaultContactInfo);
        } catch (e) {
            console.log("Error in submitting form", e);
            alertMessageText.current = `Error!`
        }
    };

    return {
        monitors: monitors.current,
        alertMessageText: alertMessageText.current,
        findMonitor,
        sendContactInformation
    }
}

export default useMonitor

