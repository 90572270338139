import React, { useEffect, useState, useCallback } from 'react';
import { GoogleMap, Marker, TrafficLayer, useJsApiLoader } from '@react-google-maps/api';
import { Button, ButtonGroup, IconButton } from '@chakra-ui/react';
import { FaCrosshairs, FaMinus, FaPlus } from 'react-icons/fa';
import IconCE7 from '../assets/images/markers/locationSVG.svg';
import { useNavigate } from 'react-router-dom';

const containerStyle = {
  width: '100vw',
  height: '100vh',
};

const MyMap = ({isLoaded}) => {
  const navigate = useNavigate();
  const [currentLocation, setCurrentLocation] = useState(null);
  const [zoom, setZoom] = useState(16)
  const [error, setError] = useState(null);
  const [center, setCenter] = useState({
    lat: 48.8584,
    lng: 2.2945,
  })

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        position => {
          const { latitude, longitude } = position.coords;
          setCurrentLocation({ lat: latitude, lng: longitude });
          setCenter({ lat: latitude, lng: longitude });
        },
        error => {
          setError(error.message);
        }
      );
    } else {
      setError('Geolocation is not supported by this browser.');
    }
  }, []);

  const centerMapOnUserLocation = useCallback(() => {
    if (currentLocation) {
      setCenter({ lat: currentLocation.lat, lng: currentLocation.lng });
    }
  }, [currentLocation]);
  console.log("currentLocation",currentLocation)
  const customIcon = {
    url: IconCE7,
    scaledSize: new window.google.maps.Size(62, 62),
    origin: new window.google.maps.Point(0, 0),
    anchor: new window.google.maps.Point(16, 32),
  };

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={center}
      zoom={zoom}
      clickableIcons={false}
      options={{
        mapTypeControl: false,
        streetViewControl: false,
        fullscreenControl: false,
        zoomControl: false,
      }}
      // onUnmount={onUnmount}
    >
      {/* Traffic Layer */}
      <TrafficLayer />

      {/* Display current location marker */}
      {currentLocation && <Marker position={currentLocation} icon={customIcon} />}

      {/* Center user location button */}
      <ButtonGroup position='absolute' top={2} right={2} spacing={0}>
        <IconButton className='backgroundColorButton' icon = {<FaCrosshairs color='#fff' />} onClick={centerMapOnUserLocation}></IconButton>
        <IconButton className='backgroundColorButton' icon = {<FaPlus color='#fff' />} onClick={() => setZoom(zoom => zoom + 1)}></IconButton>
        <IconButton className='backgroundColorButton' icon = {<FaMinus color='#fff' />} onClick={() => setZoom(zoom => zoom - 1)}></IconButton>
      </ButtonGroup>

      <Button size='md' className='localizacion' onClick={() => navigate('/emergencias')}>
        Pulsa aquí para enviar tu localización
      </Button>
      <Button top={2} size='md' className='currentLocation'>
      Esta es mi ubicación
      </Button>
    </GoogleMap>
  ) : (
    <div>
      {isLoaded ? <div>Error loading Google Maps</div> : <div>Loading...</div>}
    </div>
  );
};

export default MyMap;
